.navbar_desktop {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    z-index: 2;
    background: none;
  
    @media screen and(max-width: 1024px){
      padding: 1.5rem
    }
  }
  
  .navbar_desktop-logo {
    display: flex;
    position: relative;
    flex-direction: row;
    width: fit-content;
    height: 3.25rem;
    justify-content: center;
    align-items: center;
    background: none;
    overflow: hidden;
  
    svg {
      font-size: clamp(12em, 18em, 18vw);
      color: var(--neutral-light);
      background: none;
    }
  }
  
  .navbar_desktop-menu {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    background: none;
    gap: 3rem;  
  
    @media screen and(max-width: 1366px){
      gap: 1.5rem;
    }
  
    @media screen and(max-width: 1024px){
      display: none;
    }
  }
  
  .desktop_menu-cta {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: none;
    transition: all 2s ease-in-out;
  
    &:hover {
      div {
        width: 100%;
      }
    }
  
    a {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-size: clamp(1em, 1.5em, 1.25vw);
      font-weight: 500;
      letter-spacing: 0.06em;
      text-decoration: none;
      text-transform: uppercase;
      background: none;
      color: var(--neutral-light);
    }
  
    div {
      display: flex;
      position: relative;
      width: 8px;
      height: 2px;
      align-self: flex-start;
      background-color: var(--neutral-light);
      transition: all 0.8s ease-out;
    }
  }