.footer {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    padding: 4rem 2rem;
    justify-content: space-between;
    align-items: center;
    background: none;
    margin-top: 5rem;

    @media screen and(max-width: 1600px){
        flex-direction: column;
        padding: 1.5rem;
        gap: 1.5rem;
        margin-top: clamp(3rem, 5rem, 4vw);
    }
}

.footer__logo {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    background: none;
    align-items: center;
    padding: 0;
    gap: 1.5rem;
    width: fit-content;
    height: 3rem;
    overflow: hidden;

    svg {
        font-size: 14em;
        color: #483B32;
        background: none;
    }

    p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        width: 412px;
        height: fit-content;
        color: #483B32;

        @media screen and(max-width: 1024px){
            width: 100%;
        }
    }

    @media screen and(max-width: 1600px) {
        height: 8rem;
        justify-content: center;
    }

    @media screen and(max-width: 1024px){
        flex-direction: column;
    }
}

.footer__links {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 720px;
    height: fit-content;
    background: none;

    a {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        width: fit-content;
        height: fit-content;
        background: none;
        text-decoration: none;
        gap: 8px;
        align-items: center;
        
        p {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            color: var(--neutral-dark);
            background: none;
            text-decoration: none;
        }

        svg {
            font-size: 1.5em;
            color: var(--neutral-dark);
            background: none;
            text-decoration: none;
        }

        @media screen and(max-width: 1024px){
            gap: 1.5rem;
        }
    }

    @media screen and(max-width: 1024px) {
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 2rem;
    }
}