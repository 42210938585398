@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

:root {
  --font-main: "Lato", sans-serif;
  --primary-color: #3a9659;
  --secondary-color: #7d83ff;
  --semantic-danger: #d34423;
  --semantic-danger_soft: #e9bfb5;
  --semantic-warning: #dbab03;
  --semantic-success: #64e890;
  --semantic-safety: #91cdfa;
  --neutral-dark_2: #374758;
  --neutral-dark_3: #15212d;
  --neutral-light_2: #d4e1f0;
  --neutral-light_3: #b7cee9;
  --neutral-dark: #483b32;
  --neutral-light: #ebf2fa;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  scrollbar-color: #3075c5 #b7cee9;
  scrollbar-width: thin;
  background: var(--neutral-light);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  font-family: var(--font-main);
  -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #b7cee9;
}

::-webkit-scrollbar-thumb {
  background-color: #3075c5;
  height: 64px;
}

.Toastify__toast {
  background: none;
}
.Toastify__toast-container {
  background: none;
}
.Toastify__toast-body {
  background: none;
}
.Toastify__toast-icon {
  background: none;
}
.Toastify__toast-icon svg {
  background: none;
}
.Toastify__toast-body div {
  background: none;
}
.Toastify__close-button {
  background: none;
}
.Toastify__close-button svg {
  background: none
}

.calendarform-loader {
  --uib-size: 80px;
  --uib-speed: 1.55s;
  --uib-color: var(--neutral-dark);
  --uib-line-weight: 5px;
  position: relative;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  height: var(--uib-line-weight);
  width: var(--uib-size);
  border-radius: calc(var(--uib-line-weight) / 2);
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.calendarform-loader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--uib-color);
  opacity: 0.1; 
}

.calendarform-loader::after {
  content: "";
  height: 100%;
  width: 100%;
  border-radius: calc(var(--uib-line-weight) / 2);
  animation: loader var(--uib-speed) ease-in-out infinite;
  transform: translateX(-90%);
  background-color: var(--uib-color); 
}

.calendar-loader {
  --uib-size: 80px;
  --uib-speed: 1.55s;
  --uib-color: var(--neutral-dark);
  --uib-line-weight: 5px;
  position: relative;
  display: flex;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;
  height: var(--uib-line-weight);
  width: var(--uib-size);
  border-radius: calc(var(--uib-line-weight) / 2);
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.calendar-loader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--uib-color);
  opacity: 0.1; 
}

.calendar-loader::after {
  content: "";
  height: 100%;
  width: 100%;
  border-radius: calc(var(--uib-line-weight) / 2);
  animation: loader var(--uib-speed) ease-in-out infinite;
  transform: translateX(-90%);
  background-color: var(--uib-color); 
}

@keyframes loader {
  0%,
  100% {
  transform: translateX(-90%);
  }

  50% {
  transform: translateX(90%);
  }
}