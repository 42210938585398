.chatscard__wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    align-items: center;
    gap: 16px;
    background-color: var(--neutral-light);
    border-radius: 10px;
    padding: 24px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;

    @media screen and(max-width: 1440px){
        height: fit-content;
    }
}

.chatscard_wrapper-footer {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    background: none;
    align-items: center;
    justify-content: center;

    p {
        font-style: normal;
        font-size: 0.75em;
        font-weight: 400;
        text-transform: uppercase;
        color: #374758;
        background: none;
        text-align: center;
    }
}

.selecteddatedetails__wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    gap: 24px;
    background: none;
    order: 1;
    flex-grow: 1;
    border-radius: 12px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;

    @media screen and(max-width: 1440px){
        height: 498px;
    }
    @media screen and(max-width: 648px){
        padding: 0 clamp(1.5rem, 2rem, 1.7vw);
        height: fit-content;
    }
}

.chatscard__wrapper::-webkit-scrollbar,
.selecteddatedetails__wrapper::-webkit-scrollbar
{
    display: none;
}

.cdashboard__deleteconfirmation-modal {
    display: flex;
    position: fixed;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 320px;
    // min-width: 320px;
    height: fit-content;
    // min-height: 220px;
    background-color: #B7CEE9;
    border-radius: 16px;
    align-items: flex-start;
    z-index: 2;
    left: 50%;
    top: 50%;
    box-shadow: 0px 0px 100px 1036px rgba(90, 114, 143, 0.42);
    transition: all 0.4s ease-out;

    @media screen and(max-width: 1440px){
        left: unset;
        align-self: center;
    }
}

.cdashboard__dcmodal-title {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    justify-content: center;
    background: none;
    padding: 16px;
    border-bottom: 1px solid #D4E1F0;

    p {
        font-style: normal;
        font-size: 1.5em;
        font-weight: 700;
        text-align: left;
        text-transform: capitalize;
        background: none;
        color: #374758;
    }
}

.cdashboard__dcmodal-message {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    justify-content: center;
    background: none;
    padding: 16px;
    border-bottom: 1px solid #D4E1F0;

    p {
        font-style: normal;
        font-size: 1em;
        font-weight: 500;
        text-align: center;
        color: #667F9B;
        background: none;
    }
}

.cdashboard__dcmodal-ctas {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    justify-content: center;
    background: none;
    padding: 16px;
    gap: 16px;

    div:nth-child(1) {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        width: fit-content;
        height: fit-content;
        align-items: flex-start;
        justify-content: center;
        background-color: #D34423;
        border-radius: 6px;
        padding: 6px;
        cursor: pointer;
        transition: all 0.4s ease-out;

        &:hover {
            background-color: #892B16;
        }

        p {
            font-style: normal;
            font-size: 1em;
            font-weight: 700;
            text-align: center;
            color: var(--neutral-light);
            background: none;
        }
    }

    div:nth-child(2) {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        width: fit-content;
        height: fit-content;
        align-items: flex-start;
        justify-content: center;
        background-color: #D4E1F0;
        border-radius: 6px;
        padding: 6px;
        cursor: pointer;
        transition: all 0.4s ease-out;

        &:hover {
            background-color: #FBFCFE;
        }

        p {
            font-style: normal;
            font-size: 1em;
            font-weight: 700;
            text-align: center;
            color: #374758;
            background: none;
        }
    }
}

h1, h2, h3, h4, p, div, svg {
    background: none;
  }