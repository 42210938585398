.mobilenavbar {
    display: flex;
    position: fixed;
    width: fit-content;
    height: fit-content;
    background: none;
    overflow: hidden;
    padding: 0;
    right: 32px;
    margin: 0;
    align-items: center;
    justify-content: center;

    @media screen and(min-width: 1025px){
        display: none;
    }

    @media screen and(max-width: 1024px){
        right: 24px;
    }
}

.background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: #483B32;
}

.navbuttons__wrapper {
    display: none;
    position: fixed;
    gap: 4rem;
    top: 25%;
    left: 0;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    align-items: center;
    background: none;
}

.navbarbutton {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: var(--neutral-light);
    cursor: pointer;
}