.entrieslist {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    max-height: 320px;
    align-items: flex-start;
    padding: 6px;
    gap: 8px;
    border-radius: 6px;
    background-color: #d4e1f0;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
}

.entrieslist::-webkit-scrollbar {
    display: none;
}

.financeform__header {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    background: none;

    svg {
        font-size: clamp(1.25em, 1.5em, 1.25vw);
        color: #374758;
        background: none;
    }
    
}

.financeform__header-buttonswrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    background: none;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}

.financeform__header-controls {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    background-color: #D4E1F0;
    border-radius: 6px;
    padding: 4px;

    svg {
        font-size: clamp(1em, 1.25em, 1vw);
        color: #374758;
        background: none;
    }
}

.financeformheader__picker-selected {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    gap: 4px;
    padding: 6px;
    border-radius: 6px;
    background-color: #374758;
    justify-content: center;
    cursor: pointer;
    
    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 500;
        color: #D4E1F0;
        text-align: left;
        text-transform: capitalize;
        background: none;
    }

    svg {
        font-size: clamp(0.75em, 1em, 1vw);
        color: #D4E1F0;
        background: none;
    }
}

.typeofregistry__picker {
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    background-color: #374758;
    margin-top: 12px;
    align-self: center;
    justify-self: center;
    top: 1.5rem;
    overflow: hidden;
    z-index: 1;

    div {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        height: fit-content;
        padding: 6px;
        align-items: center;
        justify-content: center;
        background: none;
        transition: all 0.3s ease-out;
        gap: 4px;
        
        &:hover {
            background-color: #6A849F;
        }

        p {
            font-style: normal;
            font-size: clamp(0.75em, 1em, 1vw);
            font-weight: 500;
            color: #D4E1F0;
            text-align: center;
            text-transform: capitalize;
            background: none;
        }
    
        svg {
            font-size: clamp(0.75em, 1em, 1vw);
            color: #D4E1F0;
            background: none;
        }
    }
}

.fiform__fieldswrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    gap: 12px;
    background: none;
}

.fiform__fieldrow {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    border-radius: 6px;
    padding: 6px;
    align-items: center;
    justify-content: space-between;
    background-color: #D4E1F0;
    gap: 1rem;
}

.fiformfieldrow__title {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    background: none;
    align-items: flex-start;
    justify-content: left;

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 600;
        color: #374758;
        text-align: left;
        text-transform: uppercase;
        background: none;
    }
}

.typeof__picker {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    padding: 6px;
    border-radius: 6px;
    background-color: #B7CEE9;
    align-items: center;
    justify-content: center;
    gap: 4px;
    max-width: clamp(192px, 260px, 13.5vw);
    cursor: pointer;

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 400;
        color: #374758;
        text-align: center;
        text-transform: capitalize;
        background: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    svg {
        font-size: clamp(0.75em, 1em, 1vw);
        color: #374758;
        background: none;
    }
}

.typeof__ddown {
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    border-radius: 6px;
    background-color: #374758;
    align-items: center;
    justify-content: center;
    top: 2.25rem;
    overflow: hidden;
    right: -1rem;
    z-index: 1;

    div {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        height: fit-content;
        padding: 6px;
        background: none;
        align-items: center;
        justify-content: center;
        background: none;
        transition: all 0.3s ease-out;
        cursor: pointer;
        
        &:hover {
            background-color: #8EA6C1;
        }

        p {
            font-style: normal;
            font-size: clamp(0.75em, 1em, 1vw);
            font-weight: 500;
            color: #D4E1F0;
            text-align: center;
            text-transform: capitalize;
            background: none;
        }
    
        svg {
            font-size: clamp(0.75em, 1em, 1vw);
            color: #D4E1F0;
            background: none;
        }
    }
}

.fiformfieldrow__input {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    max-width: clamp(120px, 180px, 15vw);
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 6px;
    background-color: #B7CEE9;
    border: none;
    font-style: normal;
    font-size: clamp(0.75em, 1em, 1vw);
    font-weight: 500;
    color: #374758;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    outline: none;
    appearance: none;
    transition: all 0.3s ease-out;

    &:focus {
        border: none;
        appearance: none;
        text-shadow: none;
        box-shadow: none;
        outline: none;
    }

    &::placeholder {
        color: rgba(48, 58, 70, 0.6);
    }

    @media screen and(max-width: 1440px){
        max-width: clamp(180px,212px,25vw);
    }
}

.fiformfieldrow__fixedinput {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    border-radius: 6px;
    padding: 6px;
    align-items: center;
    justify-content: center;
    background-color: #B7CEE9;

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 500;
        color: #374758;
        background: none;
        text-align: center;
        text-transform: capitalize;
    }
}

.rowwrapper__value-wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #B7CEE9;
    border-radius: 6px;
    width: fit-content;
    height: fit-content;
    min-width: 26px;
    min-height: 27px;
    padding: 6px;
    overflow: hidden;
    gap: 4px;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(.75em,1em,1vw);
        color: #374758;
        background: none; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        &:focus {
            border: none;
            appearance: none;
            text-shadow: none;
            box-shadow: none;
            outline: none;
        }
    }

    svg {
        font-size: 1em;
        color: #374758;
        background: none;
    }
    
}

[contentEditable=true]:empty:before {
    content: attr(placeholder);
    font-style: normal;
    font-weight: 400;
    font-size: clamp(.75em,1em,1vw);
    color: rgba(48, 58, 70, 0.6);
    background: none; 
}