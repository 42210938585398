.allentries__loader-container {
    width: 3.25em;
    transform-origin: center;
    animation: rotate4 2s linear infinite;
    background: none;
}
   
.allentries__loader-stroke {
    fill: none;
    stroke: #483B32;
    stroke-width: 4;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash4 1.5s ease-in-out infinite;
    background: none;
}
   
@keyframes rotate4 {
    100% {
        transform: rotate(360deg);
    }
}
   
@keyframes dash4 {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    
    50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
    }
    
    100% {
        stroke-dashoffset: -125px;
    }
}
   