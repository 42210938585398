.chat {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 61.5rem;
    align-items: flex-start;
    z-index: 1;
    background: none;
    padding: 0;

    @media screen and(max-width: 1440px){
        flex-direction: column;
        height: fit-content;
    }
}

.chatbox__container {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 75%;
    height: 100%;
    align-items: flex-start;
    border-radius: 32px;
    background-color: #B7CEE9;
    // padding: 32px;
    z-index: 1;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media screen and(max-width: 1440px){
        height: 90vh;
        width: 100%;
    }
}

.chatbox__headecontainer {
    display: flex;
    position: absolute;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 6rem;
    top: 0;
    left: 0;
    background-color: var(--neutral-dark);
    align-items: center;
    justify-content: center;
}

.chaboxheadcont__wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    background: none;
    gap: 16px;

    div:nth-child(1) {
        display: flex;
        position: relative;
        width: clamp(2.25rem, 3rem, 2.5vw);
        height: clamp(2.25rem, 3rem, 2.5vw);
        background: none;
        border-radius: 50%;
        align-items: center;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    div:nth-child(2) {
        display: flex;
        position: relative;
        flex-direction: column;
        flex-wrap: nowrap;
        width: fit-content;
        height: fit-content;
        gap: 2px;
        background: none;

        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: clamp(1em, 1.5em, 1.25vw);
            text-align: left;
            text-transform: capitalize;
            background: none;
            color: var(--neutral-light);
        }

        div {
            display: flex;
            position: relative;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            height: fit-content;
            background: none;
            gap: 4px;
            align-items: center;

            div {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #36CD69;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 0.75em;
                text-align: left;
                text-transform: lowercase;
                background: none;
                color: #36CD69;
            }
        }
    }
}

.chatbox__noroomheader {
    display: flex;
    position: relative;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
    background: none;
    align-items: center;
    justify-content: center;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: clamp(1em, 1.5em, 1.25vw);
        text-align: left;
        background: none;
        color: var(--neutral-light);
    }
}

.chatbox__messagebar {
    display: flex;
    position: absolute;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 75%;
    height: 64px;
    align-self: center;
    bottom: 32px;
    background-color: #D4E1F0;
    box-shadow: 0px 115px 46px rgba(23, 35, 49, 0.01), 0px 65px 39px rgba(23, 35, 49, 0.04), 0px 29px 29px rgba(23, 35, 49, 0.07), 0px 7px 16px rgba(23, 35, 49, 0.08), 0px 0px 0px rgba(23, 35, 49, 0.08);
    border-radius: 24px;
    align-items: center;
    justify-content: space-between;
    padding: 32px;

    @media screen and(max-width: 1440px){
        width: 90vw;
    }
}

.chabomessagebar__fileattach {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    background: none;
    align-items: center;
    gap: 12px;

    svg {
        font-size: 1.5em;
        color: #374758;
        background: none;
    }

    div {
        height: 32px;
        width: 0px;
        border: 1px solid #8AA6C5;
        background: none;
    }
}

.chabomessagebar__messageinput {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    justify-content: left;
    background: none;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    outline: none;
    appearance: none;
    user-select: none;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: clamp(0.75em, 1em, 1.6vw);
    color: #374758;
    text-align: center;
    background: none;
    padding: 0 2rem;
    transition: all 0.3s ease-out;

    &:focus {
        border: none;
        appearance: none;
        text-shadow: none;
        box-shadow: none;
        outline: none;
    }

    &::placeholder {
        color: #6c89aa;
    }
}

.chabomessagebar__sendbutton {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 2rem;
    height: 2rem;
    background-color: #374758;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    user-select: none;
    outline: none;
    text-shadow: none;
    appearance: none;
    box-shadow: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.4s ease-out;

    svg {
        font-size: 1.5em;
        color: #D4E1F0;
        background: none;
    }
}

.chatdetails__container {
    display: flex;
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    right: 0;
    width: 28%;
    height: 61.5rem;
    align-items: flex-start;
    z-index: 0;
    background-color: #D4E1F0;
    border-radius: 32px;
    padding: clamp(1.5rem,2rem,1.666vw) clamp(1.5rem,2rem,1.666vw) clamp(1.5rem,2rem,1.666vw) clamp(3rem,5.5rem,5vw);
    // justify-content: space-between;
    gap: 1rem;

    @media screen and(max-width: 1440px){
        position: relative;
        width: 100%;
        margin-top: -4rem;
        padding: clamp(1.5rem,2rem,1.666vw);
        padding-top: 6rem;
        height: fit-content;
        gap: 2rem;
    }
}

.chatdetails__header {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    background: none;
    justify-content: space-between;

    h4 {
        font-style: normal;
        font-size: clamp(1em, 1.5em, 1.25vw);
        font-weight: 700;
        color: #374758;
        background: none;
        text-transform: uppercase;
        letter-spacing: 0.04em;
    }
}

.chatdetails__header-backbutton {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    background: none;
    cursor: pointer;

    svg {
        font-size: 1.5em;
        color: #374758;
        background: none;
    }
}

.chatrooms__wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    background: none;
    gap: 1rem;    
}

.chatrooms__norooms {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    justify-content: center;
    background: none;

    p {
        font-style: normal;
        font-size: 1em;
        font-weight: 500;
        text-align: left;
        color: #5D7086;
        background: none;
    }
}

.chatrooms__pills {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    padding: 8px;
    border-radius: 16px;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #B7CEE9;
    cursor: pointer;
    transition: all 0.4s ease-out
}

.chatroom__pills-profilewrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
    align-items: center;
    gap: 12px;
    background: none;
    padding: unset;
    justify-content: unset;

    div:nth-child(1) {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: none;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: center;
        }
    }

    div:nth-child(2) {
        display: flex;
        position: relative;
        flex-direction: column;
        flex-wrap: nowrap;
        width: fit-content;
        max-width: 120px;
        height: fit-content;
        align-items: flex-start;
        gap: 4px;
        background: none;

        h5 {
            font-style: normal;
            font-size: 12px;
            font-weight: 700;
            text-align: left;
            color: #374758;
            background: none;
            text-transform: capitalize;
        }

        p {
            font-style: normal;
            font-size: 12px;
            font-weight: 400;
            text-align: left;
            color: #5D7086;
            background: none;
            max-width: 120px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

.chatroom__pills-roomdetails {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    gap: 6px;
    background: none;
    align-items: flex-start;

    div:nth-child(1) {
        background: none;

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            text-align: left;
            text-transform: capitalize;
            background: none;
            color: #5D7086;
        }
    }

    div:nth-child(2) {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        height: fit-content;
        align-items: flex-start;
        justify-content: space-between;
        gap: 4px;
        background: none;

        svg {
            font-size: 1em;
            color: #5D7086;
            background: none;
        }

        div {
            display: flex;
            position: relative;
            flex-direction: column;
            flex-wrap: nowrap;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #7D83FF;
            align-items: center;
            justify-content: center;
            
            p {
                font-style: normal;
                font-weight: 500;
                font-size: 8px;
                text-align: center;
                background: none;
                color: var(--neutral-light); 
            }
        }
    }
}

.chatdetails__datawrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    gap: 12px;
    background: none;
    align-items: flex-start;
}

.chadawra__rowwrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    background-color: #B7CEE9;
    padding: 6px;
    border-radius: 6px;
    align-items: center;
    justify-content: space-between;
}

.chadawrarowwra__fieldtitle {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    background: none;
    align-items: flex-start;

    h5 {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 600;
        color: #374758;
        background: none;
        text-transform: uppercase;
        text-align: left;
    }
}

.chadawrarowwra__datafieldwrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    gap: 8px;
    background: none;
}

.chadawrarowwra__datafield {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 6px;
    background-color: #D4E1F0;
    gap: 8px;

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 400;
        color: #374758;
        background: none;
    }

    svg {
        font-size: clamp(0.75em, 1em, 1vw);
        color: #374758;
        background: none;
    }
}

.detailslocation__faqs {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    gap: 20px;
    background-color: #DBAB03;
    border-radius: 6px;
    overflow: hidden;
    padding: 12px;
}

.dlfaqs__header {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    background: none;

    h3 {
        font-style: normal;
        font-size: clamp(1em, 1.25em, 1.25vw);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        color: #15212D;
        background: none;
        text-align: left;
    }

    svg {
        font-size: clamp(1.25em, 1.5em, 1.5vw);
        color: #15212D;
        background: none;
    }
}

.dlfaqs__qacontainer {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    background: none;
    gap: 12px;
    align-items: flex-start;

    h4 {
        font-style: normal;
        font-size: clamp(1em, 1.25em, 1.1vw);
        font-weight: 600;
        color: #15212D;
        text-align: left;
        background: none;
    }

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 400;
        color: #15212D;
        margin-left: 12px;
        max-height: 60px;
        background: none;
    }
}

.chatdetails__importantbox {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    padding: 12px;
    gap: 20px;
    background-color: #DBAB03;
    border-radius: 6px;
}

.chatdetails__importantbox-header {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    background: none;

    h4 {
        font-style: normal;
        font-weight: 600;
        font-size: clamp(0.875em, 1.25em, 1.25vw);
        letter-spacing: 0.04em;
        color: #15212D;
        text-transform: uppercase;
        background: none;
    }

    svg {
        font-size: clamp(1em, 1.5em, 1.5vw);
        color: #15212D;
        background: none;
    }
}

.chatdetails__importantbox-content {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    gap: 12px;
    background: none;
}

.importantbox__content-row {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    background: none;

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1.25em, 1vw);
        font-weight: 400;
        color: #15212D;
        background: none;
    }
}

.chatbox__messages-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    background: none;
    padding: 6rem 2rem 5rem 2rem;
    overflow-y: scroll;
    gap: 1rem;

    @media screen and(max-width: 720px){
        padding: 6rem 1.5rem 5.5rem 1.5rem;
    }
}

.messages__row-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    justify-content: center;
    padding: 1rem 0;
    background: none;
    gap: 6px;
    overflow: unset;
}

.messages__box {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    max-width: 640px;
    height: fit-content;
    padding: 12px;
    align-items: flex-start;

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 3vw);
        font-weight: 400;
        width: 100%;
        height: auto;
        background: none;
        overflow-wrap: break-word;
    }

    @media screen and(max-width: 1440px){
        max-width: 480px
    }
    @media screen and(max-width: 1024px){
        max-width: 240px
    }
}

.messages__timestamp {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    padding: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    p {
        font-style: normal;
        font-size: 0.75em;
        font-weight: 400;
        background: none;
    }
}

.messages__nomessages {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    background: none;

    p {
        font-style: normal;
        font-size: 1em;
        font-weight: 400;
        background: none;
        color: #5D7086;
    }
}

.chatdetails__activeprofile-sectionpillswrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    gap: 1rem;
    background: none;
}

.activeprofile__sectionpills {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    padding: 6px;
    align-items: center;
    justify-content: center;
    background: none;
    border: 2px solid #374758;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.4s ease-out;

    &:hover {
        background-color: #374758;

        p {
            color: #EBF2FA;
        }
    }

    p {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #374758;
        background: none;
        text-transform: capitalize;
    }
}

.activeprofile__datamutation-controls {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    align-self: flex-end;
    width: fit-content;
    height: fit-content;
    background-color: #B7CEE9;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    padding: 4px;
    cursor: pointer;

    svg {
        font-size: 1em;
        color: #374758;
        background: none;
    }
}

.chatdeails__profileheader {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    gap: 8px;
    background: none;

    div {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 1.5rem;
        height: 1.5rem;
        background: none;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: center;
        }
    }

    h4 {
        font-style: normal;
        font-size: clamp(1em, 1.5em, 1.25vw);
        font-weight: 700;
        color: #374758;
        background: none;
        text-transform: capitalize;
        letter-spacing: unset;
    }
}

.chatdetails__sectiondetails-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    padding: 12px;
    gap: 1rem;
    background-color: #B7CEE9;
    border-radius: 16px;
}

.sectiondetails__row-wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    border-radius: 6px;
    align-items: center;
    justify-content: space-between;
    background-color: #D4E1F0;
    padding: 6px;

    h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #374758;
        background: none;
        text-transform: uppercase;
    }
}

.chatrowwrapper__value-wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #B7CEE9;
    border-radius: 6px;
    width: fit-content;
    height: fit-content;
    min-width: 26px;
    min-height: 27px;
    padding: 6px;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(.75em,1em,1vw);
        color: #374758;
        background: none; 
        
        &:focus {
            border: none;
            appearance: none;
            text-shadow: none;
            box-shadow: none;
            outline: none;
        }
    }
    
}

[contentEditable=true]:empty:before {
    content: attr(placeholder);
    font-style: normal;
    font-weight: 400;
    font-size: clamp(.75em,1em,1vw);
    color: rgba(48, 58, 70, 0.6);
    background: none; 
}

.rowwrapper__valuevariant-wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #B7CEE9;
    border-radius: 6px;
    width: fit-content;
    height: fit-content;
    padding: 6px;
    gap: 8px;
    
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #374758;
        background: none;   
    }

    svg {
        font-size: 1em;
        color: #DBAB03;
        background: none;
    }
}

.rowwrapper__input-wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    width: fit-content;
    max-width: clamp(80px, 120px, 10.2vw);
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 6px;
    background-color: #B7CEE9;
    border: none;
    font-style: normal;
    font-size: 0.875em;
    font-weight: 500;
    color: #374758;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    outline: none;
    appearance: none;
    transition: all 0.3s ease-out;

    &:focus {
        border: none;
        appearance: none;
        text-shadow: none;
        box-shadow: none;
        outline: none;
    }

    &::placeholder {
        color: #677C92;
    }

    @media screen and(max-width: 1440px){
        max-width: clamp(80px, 180px, 18.2vw);
    }
    @media screen and(max-width: 640px){
        max-width: clamp(80px, 180px, 45.2vw);
    }
}

.rowwrapper__input-datewrapper {
    display: flex;
    position: relative;
    // flex-direction: row;
    width: fit-content;
    max-width: clamp(80px, 120px, 10.2vw);
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 6px;
    background-color: #B7CEE9;
    border: none;
    font-style: normal;
    font-size: 0.875em;
    font-weight: 500;
    color: #374758;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    outline: none;
    appearance: none;
    transition: all 0.3s ease-out;

    // &:focus {
    //     border: none;
    //     appearance: none;
    //     text-shadow: none;
    //     box-shadow: none;
    //     outline: none;
    // }

    // &::placeholder {
    //     color: #677C92;
    // }

    @media screen and(max-width: 1440px){
        max-width: clamp(80px, 180px, 18.2vw);
    }
    @media screen and(max-width: 640px){
        max-width: clamp(80px, 180px, 45.2vw);
    }
}

.rowwrapper__multiplevalue-wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: none;
    border-radius: 6px;
    width: fit-content;
    height: fit-content;
    gap: 8px;
}

.rowwrapper__createreservation-cta {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    background-color: #374758;
    border-radius: 6px;
    width: fit-content;
    height: fit-content;
    padding: 6px;
    gap: 8px;
    cursor: pointer;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #EBF2FA;
        background: none;            
    }

    svg {
        font-size: 14px;
        color: #EBF2FA;
        background: none;
    }
}

.chatresform__picker-wrapper {
    display: none;
    position: absolute;
    flex-direction: column;
    flex-wrap: none;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 6px;
    border-radius: 6px;
    background-color: #D4E1F0;
    bottom: -4.8rem;
    overflow: hidden;
    z-index: 2;

    div {
        display: flex;
        position: relative;
        flex-direction: row;
        width: fit-content;
        height: fit-content;
        align-items: center;
        justify-content: center;
        background: none;
        border-radius: 4px;
        padding: 4px;
        gap: 8px;
        cursor: pointer;
        transition: all 0.3s ease-out;

        &:hover {
            background-color: #B7CEE9;
        }

        svg {
            font-size: clamp(0.75em, 0.875em, 1vw);
            background: none;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: clamp(0.75em, 0.875em, 1vw);
            text-align: center;
            text-transform: capitalize;
            color: #374758;
            background: none;
            white-space: nowrap;
        }
    }
}

.rowwrapper__editdate-control {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    background-color: #B7CEE9;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;

    svg {
        font-size: clamp(0.75em, 0.875em, 1vw);
        color: #374758;
        background: none;
    }
}

.chat__formsubmit-modal {
    display: flex;
    position: fixed;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 320px;
    // min-width: 320px;
    height: fit-content;
    // min-height: 220px;
    background-color: #B7CEE9;
    border-radius: 16px;
    align-items: flex-start;
    z-index: 2;
    left: 50%;
    top: 50%;
    box-shadow: 0px 0px 100px 1036px rgba(90, 114, 143, 0.42);
    transition: all 0.4s ease-out;

    @media screen and(max-width: 1440px){
        left: unset;
        align-self: center;
    }
}

.chat__fsmodal-title {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    justify-content: center;
    background: none;
    padding: 16px;
    border-bottom: 1px solid #D4E1F0;

    p {
        font-style: normal;
        font-size: 1.5em;
        font-weight: 700;
        text-align: left;
        text-transform: capitalize;
        background: none;
        color: #374758;
    }
}

.chat__fsmodal-message {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    justify-content: center;
    background: none;
    padding: 16px;
    border-bottom: 1px solid #D4E1F0;

    p {
        font-style: normal;
        font-size: 1em;
        font-weight: 500;
        text-align: center;
        color: #667F9B;
        background: none;
    }
}

.cdashboard__dcmodal-ctas {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    justify-content: center;
    background: none;
    padding: 16px;
    gap: 16px;

    div:nth-child(1) {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        width: fit-content;
        height: fit-content;
        align-items: flex-start;
        justify-content: center;
        background-color: #D34423;
        border-radius: 6px;
        padding: 6px;
        cursor: pointer;
        transition: all 0.4s ease-out;

        &:hover {
            background-color: #892B16;
        }

        p {
            font-style: normal;
            font-size: 1em;
            font-weight: 700;
            text-align: center;
            color: var(--neutral-light);
            background: none;
        }
    }

    div:nth-child(2) {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        width: fit-content;
        height: fit-content;
        align-items: flex-start;
        justify-content: center;
        background-color: #D4E1F0;
        border-radius: 6px;
        padding: 6px;
        cursor: pointer;
        transition: all 0.4s ease-out;

        &:hover {
            background-color: #FBFCFE;
        }

        p {
            font-style: normal;
            font-size: 1em;
            font-weight: 700;
            text-align: center;
            color: #374758;
            background: none;
        }
    }
}