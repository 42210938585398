.allentries {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 61.5rem;
    align-items: flex-start;
    z-index: 1;
    background-color: #B7CEE9;
    border-radius: 2rem;
    padding-top: 2rem;
    gap: 2rem;

    @media screen and(max-width: 1024px) {
        height: 65rem;
        border-radius: 1.5rem;
        padding-top: 1.5rem;
        gap: 1.5rem;
    }
}

.allentries__header {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    justify-content: space-between;
    background: none;
    padding: 0 2rem;

    @media screen and(max-width: 1024px) {
        flex-direction: column;
        padding: 0 1.5rem;
        gap: 1rem;
    }
}

.allentries__header-navwrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 60%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background: none;
    order: 0;

    @media screen and(max-width: 1024px) {
        width: 100%;
    }
}
.header__navwrapper-navbutton {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    background: none;
    text-decoration: none;

    svg {
        font-size: clamp(1.5rem, 2rem, 1.66vw);
        color: #374758;
        background: none;
        order: 0;
        text-decoration: none;
    }
}
.header__navwrapper-searchbar {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    gap: 8px;
    padding: 6px;
    background-color: #D4E1F0;
    border-radius: 6px;
    order: 1;
    overflow: hidden;

    svg {
        font-size: clamp(1em,1.25em,1.25vw);
        color: #374758;
        background: none;
    }

    // div {
    //     display: flex;
    //     position: relative;
    //     flex-direction: row;
    //     flex-wrap: nowrap;
    //     width: 100%;
    //     height: fit-content;
    //     align-items: flex-start;
    //     background: none;

    //     p {
    //         font-style: normal;
    //         font-weight: 400;
    //         font-size: clamp(.75em,1em,1vw);
    //         color: #374758;
    //         background: none;
    //         width: fit-content;
    //         text-align: left;
    //         white-space: nowrap;

    //         &:focus {
    //             border: none;
    //             appearance: none;
    //             text-shadow: none;
    //             box-shadow: none;
    //             outline: none;
    //         }
    //     }
    // }
}
.navwrapper__searchbar-input {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    min-width: 90%;
    height: fit-content;
    align-items: flex-start;
    border-radius: 6px;
    background: none;
    border: none;
    font-style: normal;
    font-size: clamp(0.75em, 1em, 1.25vw);
    font-weight: 400;
    color: #374758;
    text-align: left;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    outline: none;
    appearance: none;
    white-space: nowrap;
    transition: all 0.3s ease-out;

    &:focus {
        border: none;
        appearance: none;
        text-shadow: none;
        box-shadow: none;
        outline: none;
    }

    &::placeholder {
        color: rgba(48, 58, 70, 0.6);
    }
}
.allentries__header-filterwrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    gap: 1.5rem;
    background: none;
    align-items: center;
    order: 1;

    @media screen and(max-width: 1024px) {
        width: 100%;
        justify-content: space-between;
    }
}

.header__filterwrapper-picker {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 6px;
    background-color: #D4E1F0;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.75em, 1em, 1.25vw);
        color: #374758;
        background: none;
        text-transform: capitalize;

        @media screen and(max-width: 540px){
            width: fit-content;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    svg {
        font-size: clamp(1em, 1.25em, 1.25vw);
        color: #374758;
        background: none;
    }

    @media screen and(max-width: 540px){
        max-width: 132px;
    }
}

.filterwrapper__picker-ddown {
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    border-radius: 6px;
    padding: 6px;
    background-color: #D4E1F0;
    align-items: flex-start;
    box-shadow: 0px 52px 21px rgba(15, 37, 62, 0.01), 0px 29px 18px rgba(15, 37, 62, 0.05), 0px 13px 13px rgba(15, 37, 62, 0.09), 0px 3px 7px rgba(15, 37, 62, 0.1), 0px 0px 0px rgba(15, 37, 62, 0.1);
    top: 2.5rem;
    z-index: 2;
}
.picker__ddown-option {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding: 4px 0px;
    background: none;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.75em, 1em, 1.25vw);
        color: #374758;
        background: none;
        text-transform: capitalize;
        white-space: nowrap;
    }

    svg {
        transform: rotate(-90deg);
        font-size: clamp(1em, 1.25em, 1.25vw);
        color: #374758;
        background: none;
    }
}
.picker__hdown {
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    border-radius: 6px;
    padding: 4px 8px;
    background-color: #D4E1F0;
    align-items: flex-start;
    box-shadow: 0px 52px 21px rgba(15, 37, 62, 0.01), 0px 29px 18px rgba(15, 37, 62, 0.05), 0px 13px 13px rgba(15, 37, 62, 0.09), 0px 3px 7px rgba(15, 37, 62, 0.1), 0px 0px 0px rgba(15, 37, 62, 0.1);
    left: 112%;
    top: 0;

    div {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        width: fit-content;
        height: fit-content;
        align-items: center;
        justify-content: left;
        gap: 4px;
        padding: 4px 0px;
        background: none;

        p {
            font-style: normal;
            font-weight: 400;
            font-size: clamp(0.75em, 1em, 1.25vw);
            color: #374758;
            background: none;
            text-transform: capitalize;
        }
    }
}

.allentries__contentwrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 94%;
    border-radius: 2rem;
    padding: 1.5rem;
    align-items: flex-start;
    background-color: #D4E1F0;
    gap: 1.5rem;

    @media screen and(max-width: 1024px) {
        border-radius: 1.5rem;
    }
}

.contentwrapper__table-headerswrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    gap: 12px;
    background: none;
    
    @media screen and(max-width: 1024px){
        display: none
    }
}
.table__headers {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: clamp(109px, 149px, 7.76vw);
    height: fit-content;
    align-items: center;
    justify-content: center;
    background: none;
    overflow: hidden;

    h5 {
        font-style: normal;
        font-weight: 500;
        font-size: clamp(1em, 1.25em, 1.25vw);
        color: #374758;
        background: none;
        width: fit-content;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
    }
}
.contentwrapper__table-entrieswrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: none;
    gap: 12px;
    align-items: flex-start;
}
.contentwrapper__table-entrieswrapper::-webkit-scrollbar{
    display: none;
}
.contentwrapper__table-entrywrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    padding: 12px 0px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    background-color: #B7CEE9;
    gap: 12px;
}
.table__entrywrapper-mobile {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    padding: 6px;
    border-radius: 6px;
    gap: 8px;
    background-color: #B7CEE9;
}
.entrywrapper__mobile-title {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2px 4px;
    background-color: #374758;
    border-radius: 4px;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.75em, 1em, 1.25vw);
        background: none;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #D4E1F0;
    }
}
.mobile__data-wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    align-items: flex-start;
    background: none;
}
.mobile__dwrapper-columns {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: flex-start;
    gap: 4px;
    background: none;
}
.mobile__ipaid-total {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    padding: 0px 0px 0px 4px;
    background-color: #374758;
    border-radius: 4px;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.75em, 1em, 1.25vw);
        background: none;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #D4E1F0;
    }

    div {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        width: fit-content;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 4px;
        background-color: #D4E1F0;
        border-radius: 4px;

        p {
            font-style: normal;
            font-weight: 400;
            font-size: clamp(0.75em, 1em, 1.25vw);
            background: none;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #374758;
        }
    }
}
.mobile__ipaid-detailswrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4px;
    background: none;
}
.mobile__dpill-dark {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: #374758;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.75em, 1em, 1.25vw);
        background: none;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #D4E1F0;
    }
}
.mobile__dpill-light {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: #D4E1F0;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.75em, 1em, 1.25vw);
        background: none;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #374758;
    }
}
.mobile__dpill-lightdimmed {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: #D4E1F0;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.75em, 1em, 1.25vw);
        background: none;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #607387;
    }
}
.mobile__dpill-nobg {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    border-radius: 4px;
    background: none;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.75em, 1em, 1.25vw);
        background: none;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #607387;
    }
}
.entrywrapper__datawrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: clamp(109px,149px,7.76vw);
    height: fit-content;
    align-items: center;
    background: none;
    overflow: hidden;
}
.datawrapper__datapill {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    max-width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 2px 4px;
    background-color: #374758;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.75em, 1em, 1.25vw);
        width: fit-content;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        background: none;
    }
}

.allentries__entryselected-header {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    padding: 0px 2rem;
    background: none;
}
.entryselected__header-title {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    max-width: clamp(232px, 840px, 42vw);
    height: fit-content;
    padding: 4px;
    background-color: #D4E1F0;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: clamp(1em, 1.25em, 1.25vw);
        color: #374758;
        background: none;
        width: fit-content;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.entryselected__form-container {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    background: none;
    gap: clamp(8px, 16px, 1.25vw);
}
.entryselected__form-rowwrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    background-color: #B7CEE9;
    border-radius: 6px;
}
.entryselected__form-title {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    background: none;

    p {
        font-style: normal;
        font-weight: 600;
        font-size: clamp(.75em,1em,1vw);
        color: #374758;
        background: none;
        text-align: left;
    }
}
.entryselected__notinputtype-row {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #D4E1F0;
    border-radius: 6px;
    width: fit-content;
    height: fit-content;
    min-width: 26px;
    min-height: 27px;
    padding: 6px;
    overflow: hidden;
    gap: 4px;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(.75em,1em,1vw);
        color: #374758;
        background: none; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        &:focus {
            border: none;
            appearance: none;
            text-shadow: none;
            box-shadow: none;
            outline: none;
        }
    }

    svg {
        font-size: 1em;
        color: #374758;
        background: none;
    }
}
.entryselected__ctas-wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-around;
    background: none;
    gap: 1.5rem;
}
.entryselected__cta-save {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    gap: 8px;
    
    p {
        font-style: normal;
        font-weight: 700;
        font-size: 1em;
        color: var(--neutral-light);
        background: none;
    }

    svg {
        font-size: 1em;
        color: var(--neutral-light);
        background: none;
    }
}
.entryselected__form-inputtyperow {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    max-width: clamp(120px, 180px, 15vw);
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 6px;
    background-color: #D4E1F0;
    border: none;
    font-style: normal;
    font-size: clamp(0.75em, 1em, 1vw);
    font-weight: 500;
    color: #374758;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    outline: none;
    appearance: none;
    transition: all 0.3s ease-out;

    &:focus {
        border: none;
        appearance: none;
        text-shadow: none;
        box-shadow: none;
        outline: none;
    }

    &::placeholder {
        color: rgba(48, 58, 70, 0.6);
    }

    @media screen and(max-width: 1440px){
        max-width: clamp(180px,212px,25vw);
    }
}
.entryselected__form-fixedfield {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    border-radius: 6px;
    padding: 6px;
    align-items: center;
    justify-content: center;
    background-color: #D4E1F0;

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 500;
        color: #374758;
        background: none;
        text-align: center;
        text-transform: capitalize;
    }
}
[contentEditable=true]:empty:before {
    content: attr(placeholder);
    font-style: normal;
    font-weight: 400;
    font-size: clamp(.75em,1em,1vw);
    color: rgba(48, 58, 70, 0.6);
    background: none; 
}