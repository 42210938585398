.availability {
    display: grid;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 61.5rem;
    align-items: flex-start;
    z-index: 1;
    background: none;
    padding: 0;

    @media screen and(max-width: 1440px){
        flex-direction: column;
        height: fit-content;
    }
}

.availability__details {
    display: flex;
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    right: 0;
    width: 28%;
    min-width: 460px;
    height: 61.5rem;
    align-items: flex-start;
    z-index: 0;
    background-color: #B7CEE9;
    border-radius: 32px;
    padding-top: clamp(1.5rem, 2rem, 1.7vw);
    padding-right: clamp(1.5rem, 2rem, 1.7vw);
    padding-bottom: clamp(1.5rem, 2rem, 1.7vw);
    padding-left: clamp(5rem, 5.75rem, 6vw);
    gap: 36px;
}

.details__header {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    background: none;

    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 1.5em;
        letter-spacing: 0.04em;
        color: #374758;
        text-transform: uppercase;
        background: none;
    }

    div {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 16px;
        background: none;
        align-items: center;

        svg {
            font-size: 2em;
            color: #374758;
            background: none;
        }
    }
}

.details__bkdown {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
    background: none;
    gap: 20px;
}

.bkdown__header {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    background: none;
    align-items: center;
    justify-content: space-between;

    h4 {
        font-style: normal;
        font-size: 1.25em;
        font-weight: 700;
        letter-spacing: 0.04em;
        text-align: left;
        color: #374758;
        background: none;
        text-transform: uppercase;
    }

    svg {
        font-size: 1.5em;
        color: #374758;
        background: none;
    }
}

.bkdown__content {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    gap: 8px;
    background: none;
}

.bkdown__content-status {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    border-radius: 6px;
    background-color: #D4E1F0;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
}

.content__status-period {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    background: none;
    gap: 8px;
    align-items: center;

    svg {
        font-size: 1.5em;
        background: none;
    }

    p {
        font-style: normal;
        font-size: 1.25em;
        font-weight: 400;
        color: #374758;
        background: none;
        text-align: left;
        text-transform: capitalize;
    }
}

.content__status-state {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    background: none;

    p {
        font-style: normal;
        font-size: 1.25em;
        font-weight: 400;
        color: #374758;
        background: none;
        text-align: left;
        text-transform: capitalize;
    }
}

.details__available {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    gap: 20px;
    align-items: flex-start;
    background: none;
}

.available__header {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    background: none;

    h4 {
        font-style: normal;
        font-size: 1.25em;
        font-weight: 700;
        letter-spacing: 0.04em;
        text-align: left;
        color: #374758;
        background: none;
        text-transform: uppercase;
    }

    svg {
        font-size: 1.5em;
        color: #374758;
        background: none;
    }
}

.available__content {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    gap: 8px;
    background: none;
}

.available__content-container {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    border-radius: 6px;
    background-color: #D4E1F0;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
}

.content__day {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    background: none;
    align-items: flex-start;

    p {
        font-style: normal;
        font-size: 1.25em;
        font-weight: 400;
        color: #374758;
        background: none;
        text-align: left;
        text-transform: capitalize;
    }
}

.content__periods {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    background: none;
    align-items: center;
    gap: 8px;

    svg {
        font-size: 1.5em;
        background: none;
    }
}

.available__content-more {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    background: none;
     
    svg {
        font-size: 1.5em;
        color: #374758;
        background: none;
    }
}

.available__cta {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    background: none;
    margin-top: 28px;
}