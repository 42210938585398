.formwrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    gap: 20px;
    align-items: flex-start;
    background: none;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;

    @media screen and(max-width: 1440px){
        height: fit-content;
    }
}

.formwrapper::-webkit-scrollbar {
    display: none;
}

.modal-container {
    display: flex;
    position: fixed;
    align-self: center;
    justify-self: center;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    max-width: clamp(296px, 480px, 90vw);
    height: fit-content;
    max-height: clamp(280px, 640px, 90vh);
    align-items: flex-start;
    padding: 1em;
    background-color: var(--neutral-light_2);
    border-radius: 1rem;
    box-shadow: 0px 0px 64px 808px rgba(13, 42, 75, 0.25);
    margin-bottom: 6.25rem;
    z-index: 2;
}
.modal__scroll-container {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    gap: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow: -moz-scrollbars-none;
  }
  .modal__scroll-container::-webkit-scrollbar {
    display: none;
  }
  .masked__input {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    min-width: 100%;
    height: fit-content;
    align-items: left;
    justify-content: left;
    padding: 12px;
    border-radius: 8px;
    background-color: #B7CEE9;
    border: none;
    font-style: normal;
    font-size: 1em;
    font-weight: 700;
    color: #374758;
    text-align: left;
    text-transform: capitalize;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    outline: none;
    appearance: none;
    transition: all 0.3s ease-out;
  
    &:focus {
        border: none;
        appearance: none;
        text-shadow: none;
        box-shadow: none;
        outline: 2px solid var(--neutral-dark_2);
    }
  
    &::placeholder {
        color: rgba(48, 58, 70, 0.6);
    }
  }
  .scroll__container {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    gap: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow: -moz-scrollbars-none;
  }
  .scroll__container::-webkit-scrollbar {
    display: none;
  }
  h1, h2, h3, h4, p, div, svg {
    background: none;
  }